:root {
  --font16: 16px;
  --font14: 14px;
  --mb16px: 16px;
  --mb24px: 24px;
  --mb20px: 20px;
  --textColor: #fff;
  --primary: #03A7F6;
  --mdc-theme-primary: var(--primary);
  --mat-icon-color: #989898;
  --topNavSpacing: 95px;
  --secondaryBorder: #383A42;
  --mdc-theme-surface: #151515;
  --mdc-theme-on-surface: #989898;
  // --mdc-theme-text-primary-on-background: #989898;
  --mdc-theme-text-primary-on-background: #fff;
  --mdc-typography-body1-letter-spacing:0;
  --mdc-menu-max-height: 350px; //phone input dropdown

  --mdc-outlined-text-field-error-caret-color: #f44336;
  .mat-mdc-progress-spinner{
    --mdc-circular-progress-active-indicator-color: #F1C21B;
  }
  .mat-icon{
    color: var(--mat-icon-color);
  }

  --orderInfoBoxBG: #08151C;
  --orderInfoBoxBorder: transparent;

  --listACtiveBG: #202223;
  --invoiceLightBG: #F6F8FC;
  --invoiceLightColor: #5D6481;

  --amountColor: #19213D;
  // --primaryHover: #37beff;
  --success: #36C973;
  --white: #FFFFFF;
  --grey-dark: #414042;
  --grey-light: #E5E5E5;
  --authenticationBG: #090909;
  --authColor: #fff;
  --authInnerBox: #151515;
  --textGrey: #A3AAB8;
  --textGrey2: #989898;
  --textGreyLight: #151515;
  --inputBorder: #414042;
  --app-height: 100vh;
  --bgColor: #090909;
  --themeBtnBg: #000000;
  --grey2: #989898;
  --dark: #1C1D21;
  --outStockBG:#383a41;
  --outStockShadow:black;

  --scrollBg: #555;
  --scrollThumb: #090909;
  --scrollBorder: #282727;

  // Category Filter
  --categoryBG: #1C1D21;

  // Input
  --mdc-outlined-text-field-caret-color: #fff;
  --mdc-filled-text-field-active-indicator-color: transparent;
  --mdc-filled-text-field-active-indicator-height: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
 
  --mdc-outlined-text-field-input-text-color: #fff;
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input{
    color: var(--mdc-outlined-text-field-input-text-color);
    // caret-color: var(--mdc-outlined-text-field-error-caret-color);
  }
 
  --mdc-text-button-label-text-color: var(--primary);
  --mdc-filled-button-label-text-size: var(--font16);
  --primaryfont: "DM Sans", sans-serif;
  --mdc-outlined-text-field-label-text-font: "DM Sans", sans-serif;
  --mdc-typography-subtitle2-font-family: "DM Sans", sans-serif;
  --mdc-typography-font-family:  "DM Sans", sans-serif;
  .mat-button-toggle{
    font-family: var(--primaryfont);
  }

  --mdc-filled-button-disabled-container-color: var(--textGreyLight);
  --mdc-filled-button-disabled-label-text-color: var(--grey-dark);
  .mat-mdc-unelevated-button[disabled], .mat-mdc-unelevated-button.mat-mdc-button-disabled{
    border-color: transparent !important;
  }
  .mat-mdc-button.mat-unthemed{
    --mdc-text-button-label-text-color: var(--primary);
    font-size: var(--mdc-text-button-label-text-size);
    font-weight: var(--mdc-text-button-label-text-weight);
  }
  .mat-mdc-unelevated-button.mat-unthemed{
    --mdc-filled-button-container-color: var(--primary); 
    --mdc-filled-button-label-text-color: #ffffff;
    font-weight: var(--mdc-filled-button-label-text-weight);
  }

  --mdc-snackbar-supporting-text-font: "DM Sans", sans-serif;
  --mat-table-footer-supporting-text-font: "DM Sans", sans-serif;
  --mat-table-row-item-label-text-font: "DM Sans", sans-serif;
  --mat-table-header-headline-font:  "DM Sans", sans-serif;
  --mat-table-row-item-container-height: auto;
  --mat-form-field-container-height: auto;
  --mdc-filled-button-container-shape: 8px;
  --mdc-outlined-button-container-shape: 8px;
  --mdc-filled-button-label-text-weight: 600;
  --mdc-text-button-label-text-weight: 600;
  --mdc-outlined-text-field-label-text-size: var(--font14);
  --mat-form-field-outlined-label-text-populated-size:var(--font14);
  .mat-mdc-outlined-button{
    --mdc-outlined-button-outline-color: rgb(0 203 255 / 50%);
    --mdc-outlined-button-label-text-color: #00CBFF;
    --mdc-typography-button-font-weight: 600;
  }
 
  --mdc-radio-state-layer-size: 20px;
  --mdc-text-button-label-text-size: var(--font16);
  --mdc-outlined-button-label-text-size: var(--font16);
  
  --mdc-outlined-text-field-input-text-placeholder-color: var(--textGrey2);
  --mdc-filled-text-field-caret-color: var(--white);
  --mdc-filled-text-field-input-text-color: var(--white);
  --mdc-filled-text-field-input-text-placeholder-color: var(--white);
  .mat-mdc-form-field, .mat-mdc-floating-label{
    --mat-form-field-container-text-size: var(--font14);
    --mat-form-field-container-text-tracking: 0;
    --mat-form-field-container-text-font: "DM Sans", sans-serif;

  }

  // Modal
  .mat-mdc-dialog-container{
    --mdc-dialog-container-color: #232429;
    --mdc-dialog-subhead-color: #fff;
    --mdc-dialog-supporting-text-color: #fff;
    .mdc-dialog__content{
      padding: var(--mat-dialog-content-padding, 0);
      --mdc-dialog-supporting-text-font:  "DM Sans", sans-serif;
      --mdc-dialog-supporting-text-tracking: 0;
    }
  }
  --mat-dialog-with-actions-content-padding: 0;
  --mdc-dialog-container-shape: 24px;
  --mat-dialog-content-padding: 5px 0;

  --mat-select-panel-background-color: #232429;
  --mat-dialog-container-small-max-width: 95vw;
  --mat-dialog-container-max-width: 95vw;
  --mat-option-label-text-tracking: 0;
  --mat-select-trigger-text-tracking: 0;
  --mdc-dialog-supporting-text-size: var(--font16);
 
  @media screen and (max-width: 767.98px) {
    --mdc-dialog-container-shape: 16px;
  }
  @media screen and (max-width: 575.98px) {
    --mdc-dialog-container-shape: 10px;
  }

  // mat-button-toggle
  --mat-standard-button-toggle-background-color: #03A7F614;
  --mat-standard-button-toggle-text-color: var(--textColor);
  --mat-standard-button-toggle-divider-color: transparent;
  --mat-standard-button-toggle-selected-state-background-color: var(--primary);
  --mat-standard-button-toggle-selected-state-text-color: var(--textColor);
  --mat-standard-button-toggle-shape: 8px;
  --mat-standard-button-toggle-label-text-font: "DM Sans", sans-serif;
  .mat-pseudo-checkbox{
    display: none;
  }

  // mat-fab Button
  --mdc-fab-container-shape: 14px;
  @media screen and (max-width: 767.98px) {
    --mdc-fab-container-shape: 9px;
  }
  .mat-mdc-fab, .mat-mdc-fab.mat-accent{
    --mdc-fab-container-color: var(--dark) !important;
  }

  // mat Expansion Panel
  --mat-expansion-container-background-color: transparent;
  --mat-expansion-header-text-font:  "DM Sans", sans-serif;
  --mat-expansion-header-indicator-color: var(--white);
  --mat-expansion-header-collapsed-state-height: auto;
  --mat-expansion-header-expanded-state-height: auto;
  .mat-expansion-panel-header{
    font-family: var(--primaryfont);
    height: auto !important;
    &:hover{
      background: transparent !important;
    }
  }
  .mat-expansion-panel{
    background: var(--mat-expansion-container-background-color);
  }
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after{
    color: var(--mat-expansion-header-indicator-color);
  }

  // Card
  --mdc-outlined-card-container-color: transparent;

  // DatePicker
   --mat-datepicker-calendar-container-background-color: #202020;
   --mat-datepicker-calendar-date-text-color: #e5e5e5;
   --mat-datepicker-calendar-body-label-text-color: #fff;
   --mat-datepicker-calendar-body-label-text-weight: 600;
   --mat-datepicker-calendar-header-text-color: #fff;
   --mat-datepicker-calendar-header-text-weight: 700;
   --mdc-icon-button-disabled-icon-color: rgba(255,255,255,0.38);
   --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255,255,255,0.38);
   --mat-datepicker-calendar-period-button-text-color: #fff;
   --mat-datepicker-calendar-navigation-button-icon-color: rgba(255, 255, 255, 0.54);
   --mat-datepicker-calendar-period-button-icon-color:  rgba(255, 255, 255, 0.54);
   --mat-datepicker-calendar-container-elevation-shadow: 6px 3px 17px #1c1a1ae8;
   --mat-datepicker-calendar-date-hover-state-background-color: rgba(3, 167, 246, 0.1);
   --mat-datepicker-calendar-date-selected-state-background-color: var(--primary);
  --mat-datepicker-calendar-text-font: "DM Sans", sans-serif;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  .mat-calendar{
    font-family: var(--primaryfont);
    &-arrow{
      fill: var(--mat-datepicker-calendar-period-button-icon-color);
    }
  }
  .mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button{
    color: var(--mat-datepicker-calendar-navigation-button-icon-color);
  }
  .mat-calendar-table-header, .mat-calendar-body-label{
    color: var(--mat-datepicker-calendar-body-label-text-color);
  }

  // Mat Menu
  --mat-menu-container-color: #fff;

  
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color: var(--mat-datepicker-calendar-date-hover-state-background-color) !important;
  }
  .mat-datepicker-content{
    border: 1px solid #303030;
    background-color: var(--mat-datepicker-calendar-container-background-color);
    color: var(--mat-datepicker-calendar-container-text-color);
    .mdc-button{
      font-size: var(--mat-datepicker-calendar-period-button-text-size);
    }
    .mat-calendar-body-selected{
      background-color: var(--primary);
      color: #fff !important;
    }
    th{
      color: var(--mat-datepicker-calendar-header-text-color);
      font-size: 12px;
      font-weight: var(--mat-datepicker-calendar-header-text-weight);
    }
    .mat-calendar-body-cell-content{
      border-radius: 6px;
      color: var(--mat-datepicker-calendar-date-text-color);
    }
  }

  // Table 
  --mat-table-background-color: #151515;
  --mat-table-header-headline-color: #989898;
  --mat-table-row-item-label-text-color: #E5E5E5;
  --mat-table-row-item-outline-color: rgba(137, 137, 137, 0.30);
  --mat-table-header-headline-tracking: 0;
  .mat-mdc-table{
    background: var(--mat-table-background-color);
  }
  .mdc-data-table__header-cell{
    color: var(--mat-table-header-headline-color);
    border-bottom: 1px solid var(--mat-table-row-item-outline-color);
  }
  .mdc-data-table__pagination-total, .mdc-data-table__pagination-rows-per-page-label, .mdc-data-table__cell{
    color: var(--mat-table-row-item-label-text-color);
  }

  // Select
  --mdc-filled-text-field-container-color: rgba(3, 167, 246, 0.08);
  --mat-select-trigger-text-font: "DM Sans", sans-serif;
  --mat-select-enabled-trigger-text-color: #E5E5E5;
  --mat-select-trigger-text-size: var(--font14);
  --mat-select-trigger-text-line-height: 50px;
  --mat-option-label-text-color:  #E5E5E5;
  --mat-select-placeholder-text-color: #fff;
  --mdc-filled-text-field-focus-active-indicator-color: transparent;
  --mat-select-panel-background-color: rgb(17 28 33);
  --mat-option-selected-state-label-text-color: #E5E5E5;
  --mat-option-label-text-font: "DM Sans", sans-serif;
  --mdc-typography-body1-font-size: var(--font14);
  .mat-mdc-select-value{
    color: var(--mat-select-enabled-trigger-text-color);
  }
  .mat-mdc-select-placeholder{
    color: var(--mat-select-placeholder-text-color);
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background: var(--mdc-filled-text-field-container-color);
    align-items: center;
  }
  .mdc-line-ripple{
    display: none !important;
  }

  .select-dark{
    --mdc-filled-text-field-container-color: var(--dark) !important;
    --mdc-filled-text-field-label-text-color: var(--textGrey2);
    .mat-mdc-text-field-wrapper{
      border: 1px solid #383A42;
    }
  }

  // Chips
  .mdc-evolution-chip-set .mdc-evolution-chip{

    --mdc-chip-elevated-container-color:transparent;
    --mdc-chip-container-shape-radius: 4px;
    --mdc-chip-label-text-color: var(--white);
    --mdc-chip-label-text-font: "DM Sans", sans-serif;
    --mdc-chip-label-text-size: var(--fs14);
    --mdc-form-field-label-text-size: var(--font14);
    --mdc-chip-container-height: 42px;
    border: 1px solid var(--grey-dark);
  }

  --mat-autocomplete-background-color: var(--textGreyLight);
 

  --inpurBorderColor: #383A42;
  .mat-primary{
    --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e5e5e5;
  }
  .mdc-text-field--filled{
    border-radius: 12px;
  }
  // border-radius: 12px;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  .mat-mdc-checkbox {
    --mdc-form-field-label-text-color: #989898;
    --mdc-form-field-label-text-font:  "DM Sans", sans-serif;
    --mdc-form-field-label-text-size: var(--font14);
    --mdc-checkbox-selected-focus-icon-color: var(--primary);
    --mdc-checkbox-selected-hover-icon-color: var(--primary);
    --mdc-checkbox-selected-icon-color: var(--primary);
    --mdc-checkbox-selected-pressed-icon-color: var(--primary);
    --mdc-checkbox-unselected-icon-color: #414042;
    --mdc-checkbox-state-layer-size: 20px;
    --mdc-theme-text-primary-on-background: #989898;
    // &.error-wrapper{
    //   --mdc-checkbox-unselected-icon-color: #f44336;
    // }
    &.ng-invalid.ng-touched{
      --mdc-checkbox-unselected-icon-color: #f44336;
    }
    &:hover {
      &.ng-invalid.ng-touched{
        --mdc-checkbox-unselected-hover-icon-color: #f44336;
      } 
    }
  }
  .small-select{
    --mat-select-trigger-text-line-height: 40px;
  }
  .mat-mdc-radio-button{
    &, &.mat-accent{
      --mdc-radio-unselected-icon-color: #989898;
      // --mat-radio-ripple-color: transparent;
      --mdc-radio-unselected-focus-icon-color: #989898;
      --mdc-radio-unselected-hover-icon-color: #989898;
      --mdc-radio-selected-focus-icon-color: var(--primary);
      --mdc-radio-selected-hover-icon-color: var(--primary);
      --mdc-radio-selected-icon-color: var(--primary);
      --mdc-radio-selected-pressed-icon-color: var(--primary);
      --mat-radio-ripple-color: black;
      --mat-radio-checked-ripple-color: var(--primary);
      --mdc-theme-text-primary-on-background: #989898;
      --mat-option-selected-state-label-text-color: var(--primary);
      --mdc-form-field-label-text-font: "DM Sans", sans-serif;
      --mdc-form-field-label-text-size: var(--font14);
      --mat-mdc-radio-checked-ripple-color: var(--primary);
    }
  }

  --mdc-linear-progress-track-height: 8px;
  --mdc-linear-progress-active-indicator-height: 8px;
  --mdc-linear-progress-track-shape: 64px;
  .mat-mdc-progress-bar{
    --mdc-linear-progress-active-indicator-color: #F1C21B;
    --mdc-linear-progress-track-color: #383424;
    border-radius: 40px;
  }
  .table-loader{
    --mdc-linear-progress-active-indicator-color: #03a7f6;
    --mdc-linear-progress-track-color: var(--grey-dark);
    --mdc-linear-progress-track-height: 3px;
    --mdc-linear-progress-active-indicator-height: 3px;
    z-index: 5;
  }

  .mat-mdc-mini-fab.mat-accent{
    --mdc-fab-container-color: #132027;
    --mat-mdc-fab-color: #989898;
    border: 1px solid var(--mdc-fab-small-container-color);
    box-shadow: none;
    --mdc-fab-container-shape: 50%;
  }

  .mat-mdc-unelevated-button.mat-unthemed.btn-outline-secondary{
    --mdc-filled-button-label-text-color: var(--textGrey2);
    --mdc-filled-button-container-color: transparent;
    border: 1px solid var(--inpurBorderColor);
    transition: all 400ms ease;
    &:hover{
      --mdc-filled-button-label-text-color: var(--white);
    --mdc-filled-button-container-color: #989898;
    }
  }
  .mat-mdc-icon-button {
    --mdc-icon-button-state-layer-size: 48px;
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
  }
  .btn-secondary{
    --mdc-filled-button-label-text-color: var(--textGrey2);
    --mdc-filled-button-container-color: var(--dark);
    border: 1px solid var(--scrollBorder);
  }
  .btn-danger{
    --mdc-filled-button-label-text-color: #fff;
    --mdc-filled-button-container-color: #E53935 !important;
  }

  --font24: 24px;
  --font22: 22px;
  --font21: 21px;
  --font18: 18px;
  --font20: 20px;

  --gap16px: 16px;
  --gap20px: 20px;
  --gap28px: 28px;
  --gap24px: 24px;
  --smallRoundBox: 65px;
  @media screen and (max-width: 1300px) {
    --smallRoundBox: 60px;
  }
  @media screen and (max-width: 1199.98px) {
    --smallRoundBox: 60px;
    --gap28px: 15px;
  }
  @media screen and (max-width: 991.98px) {
    --gap24px: 15px;
    --gap20px: 15px;
    --font24: 20px;
    --font22: 18px;
    --font20: 18px;
    --font21: 18px;
    --font16: 14px;
    --font18: 16px;
    --smallRoundBox: 50px;
  }
  @media screen and (max-width: 767.98px) {
    --gap28px: 10px;
    --gap24px: 10px;
    --mb16px: 10px;
    --mb20px: 15px;
    --mb24px: 15px;
    --smallRoundBox: 40px;
    --mdc-outlined-text-field-label-text-size: 12px;
    --font14: 12px;
    --gap16px: 10px;
  }


  /* Starts Light Theme Colors Changes */
  .light{
    --bgColor: #F6F8FA;
    --authenticationBG: var(--bgColor);
    --listACtiveBG: #f7f7f7;
    --authInnerBox: #fff;
    --authColor: #101326;
    --textGrey2: #667085;
    --inputBorder: #E3E9EE;
    --white: #101326;
    --textColor: #101326;
    --textGreyLight: #FFFFFF;
    --mdc-outlined-text-field-input-text-color: #667085; 
    --mdc-outlined-text-field-caret-color: #667085;
    --grey-light: #989898;
    --inpurBorderColor: #989898;
    --themeBtnBg: #FFFFFF;
    --grey2: var(--primary);
    --dark: #E9F7FE;
    --grey-dark: #E3E9EE;
    --secondaryBorder: #383A42;
    --scrollBg: #eee;
    --scrollThumb: #dedddd;
    --scrollBorder: #b6b8c1;
    --mat-standard-button-toggle-text-color: var(--primary);
    --outStockBG:#f7f7f7;
    --outStockShadow:#999;

    --mdc-theme-surface: #fff;
    --mdc-theme-on-surface: #667085;
    --mdc-theme-text-primary-on-background: #667085;

    --mat-expansion-header-indicator-color: #989898;

    --invoiceLightBG: #E3E9EE;
    --invoiceLightColor: #5D6481;

    --orderInfoBoxBG: #f5f5f5;
    --orderInfoBoxBorder: #e2e2e2;

    .graphbox-info{
      --white: #989898;
    }
    .progress-barwrap .text-grey-medium{
      --textGrey2: #989898;
    }

    .mat-mdc-mini-fab.mat-accent{
      --mdc-fab-container-color: #E9F8FF;
      border-color: rgba(3, 167, 246, 0.05);
      --mat-mdc-fab-color: var(--primary);
    }

    .category-filterbtn{
      --inpurBorderColor: #E9E9E9;
      .product-icon{
        background: #E9E9E9;
      }
      &.active{
        --white: linear-gradient(45.39deg, #00A5FC -6.5%, #00FFFF 103.76%);
        --primary: #fff;
        .product-icon{
          --primary: #03A7F6;
        }
      }
    }

    

    // Table
    --mat-table-background-color: #fff;

    // Category Filter
    --categoryBG: #F5F5F5;

    // Dialogue
    .mat-mdc-dialog-container{
      --mdc-dialog-container-color: #FFFFFF;
      --mdc-dialog-supporting-text-color:#101326;
    }

    // Autocomplete
    --mat-autocomplete-background-color: #fff;

    // Input
    --mdc-outlined-text-field-input-text-placeholder-color: var(--textGrey2);
    --mdc-filled-text-field-caret-color: #989898;
    --mdc-filled-text-field-input-text-color: #989898;
    --mdc-filled-text-field-input-text-placeholder-color: #989898;

    // Mat-select
    --mdc-filled-text-field-container-color: #F5F5F5;
    --mat-select-placeholder-text-color: #989898;
    --mat-select-panel-background-color: #fff;
    --mat-select-container-elevation-shadow: 0 4px 8px #0505053d;
    --mat-option-label-text-color: #989898;
    --mat-option-selected-state-label-text-color: #989898;
    --mat-select-enabled-trigger-text-color:  #989898;

    --mdc-filled-button-disabled-container-color: #e5e5e5;
    --mdc-filled-button-disabled-label-text-color: #b2b2b2;
    .select-bgwrap{
      --mdc-filled-text-field-container-color: #e4e4e4;
    }

   

    // Table
    --mat-table-row-item-label-text-color: #989898;
    --mat-table-header-headline-color: #414042;
    --mat-table-row-item-outline-color: #EDF0F2;

    //caeldner light mode
    --mat-datepicker-calendar-container-background-color: #fff;
    --mat-datepicker-calendar-date-text-color: #414042;
    --mat-datepicker-calendar-body-label-text-color: #000;
    --mat-datepicker-calendar-body-label-text-weight: 600;
    --mat-datepicker-calendar-header-text-color: #000;
    --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0,0,0,0.38);
    --mat-datepicker-calendar-header-text-weight: 700;
    --mat-datepicker-calendar-period-button-text-color: #000;
    --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
    --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-icon-button-disabled-icon-color: rgba(0,0,0,0.38);
    --mat-datepicker-calendar-container-elevation-shadow: 6px 3px 17px rgba(3, 167, 246, 0.1);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(3, 167, 246, 0.1);
    .mat-datepicker-content{
      border: 1px solid #d2d2d2;
      .mat-calendar-body-cell-content{
        border-radius: 6px;
      }
    }
  }
  /* Ends Light Theme Colors Changes */
  
}

$color: (
  "white": var(--white),
  "dark": var(--dark),
  "grey-dark": var(--grey-dark),
  "primary": var(--primary),
  "grey-lighter": var(--textGreyLight),
  "grey-light": var(--grey-light),
  'grey': var(--textGrey),
  'grey-medium': var(--textGrey2),
  'success': var(--success),
  'success-light': #00B42C,
  'danger': #E53935,
  'theme': var(--grey2)
);

$margin-bottom-map: (
  mb-0: 0px,
  mb-4: 4px,
  mb-6: 6px,
  mb-10: 10px,
  mb-15: 15px,
  mb-12: 12px,
  mb-16: var(--mb16px),
  mb-20: var(--mb20px),
  mb-24: var(--mb24px),
  mb-28: 28px,
);

$radius: (
  "12": 12px,
);

$fontsize: (
  "12": 12px,
  "13": 13px,
  "14": var(--font14),
  "18": var(--font18),
  "20": 20px,
  "24": var(--font24),
);

$fontweight: (
  "normal": 400,
  "medium":500,
  "semibold": 600,
  "bold": 700,
);
$gap: (
  "6px": 6px,
  "8px": 8px,
  "12px": 12px,
  "10px": 10px,
  "16px": var(--gap16px),
  "20px": var(--gap20px),
  "28px": var(--gap28px),
  "24px": var(--gap24px),
);

@mixin grid($columns: 2, $gap: 16px, $row-gap: $gap) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  gap: $gap;
  row-gap: $row-gap;
}
