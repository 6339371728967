// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$MuyBi-angular-primary: mat.define-palette(mat.$indigo-palette);
$MuyBi-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$MuyBi-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$MuyBi-angular-theme: mat.define-light-theme(
  (
    color: (
      primary: $MuyBi-angular-primary,
      accent: $MuyBi-angular-accent,
      warn: $MuyBi-angular-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($MuyBi-angular-theme);
// @import "../../../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "../src/assets/styles/_variables.scss";
@import "../src/assets/styles/_icons.scss";

label {
  @extend .fs-14;
}
body {
  background-color: var(--bgColor);
  border-color: var(--borderColor);
  color: var(--textColor);
  font-family: var(--inter-font);
  margin: 0;
  padding: 0;
  @media screen and (max-width: 767.98px) {
    font-size: 14px;
  }
}
html,
body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "DM Sans", sans-serif;
}
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
}
img {
  width: auto;
  height: auto;
  max-width: 100%;
}
input,
button,
a {
  font-family: "DM Sans", sans-serif;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
p {
  margin-top: 0;
  margin-bottom: 10px;
}
.grid {
  display: grid !important;
}
.float-right {
  float: right !important;
}
.flex {
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: -webkit-flex; /* Safari */
  // display: flex;
  display: flex;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  &-row {
    flex-direction: row;
  }
  &-grow-1 {
    flex-grow: 1;
  }
  &-shrink-0 {
    flex-shrink: 0;
  }
  &-column {
    flex-direction: column;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
  &-justify-center {
    justify-content: center;
  }
  &-justify-between {
    justify-content: space-between;
  }
  &-justify-end {
    justify-content: end;
  }
  &-align-center {
    align-items: center;
  }
  &-align-start {
    align-items: flex-start;
  }
  &-align-end {
    align-items: flex-end;
  }
  &-content-center {
    align-content: center;
  }
  &-content-between {
    align-content: space-between;
  }
}
.innerbox-child {
  display: grid;
  justify-content: end;
  grid-auto-flow: column;
  @media screen and (max-width: 767.98px) {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    .mat-mdc-form-field {
      width: 100% !important;
    }
  }
}
.grid-column {
  grid-auto-flow: column;
}
.searchbox-child {
  display: grid;
  // justify-content: end;
  grid-auto-flow: column;
  &:not(.inputwidth-auto) {
    .form-control {
      min-width: 262px;
      @media screen and (max-width: 767.98px) {
        min-width: auto;
      }
    }
  }
  @media screen and (max-width: 767.98px) {
    width: 100%;
    grid-template-columns: 1fr 180px;
    .search-inputbx {
      max-width: 100% !important;
    }
  }
  @media screen and (max-width: 575.98px) {
    grid-template-columns: 1fr 180px;
  }
}
.addedit-pic {
  width: 230px;
}
.add-edittext {
  grid-template-columns: 24px 1fr;
}
.mobile-fullgrid {
  @media screen and (max-width: 575.98px) {
    grid-template-columns: 1fr;
  }
}
.mobile-halfgrid {
  @media screen and (max-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.tablet-fullgrid {
  @media screen and (max-width: 767.98px) {
    grid-template-columns: 1fr;
  }
}
.heading-box {
  display: grid;
  justify-content: space-between;
  --width: 768px;
  --maxwidth: 767.98px;
  &:not(.tablet-single) {
    @media screen and (min-width: 768px) {
      grid-auto-flow: column;
    }
    @media screen and (max-width: 767.98px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &.tablet-single {
    @media screen and (min-width: 992px) {
      grid-auto-flow: column;
    }
    @media screen and (max-width: 991.98px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.child-space28px {
  > * {
    margin: 0 calc(var(--gap28px) / 2);
  }
}
.clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.grid2-col {
  grid-template-columns: repeat(2, 1fr);
}
.ml-auto {
  margin-left: auto;
}
.mobilegrid-colfull {
  @media screen and (max-width: 575.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
@each $class, $value in $margin-bottom-map {
  .#{$class} {
    margin-bottom: #{$value};
  }
}
.pb-16 {
  padding-bottom: var(--mb16px);
}
.ml-20px {
  margin-left: var(--mb20px);
}
.ml-16px {
  margin-left: vvar(--mb16px);
}
@each $class, $value in $color {
  .text-#{$class} {
    color: #{$value} !important;
  }
  .bg-#{$class} {
    background-color: #{$value};
  }
}
@each $class, $value in $radius {
  .radius-#{$class} {
    border-radius: #{$value};
  }
}
@each $class, $value in $fontsize {
  .fs-#{$class} {
    font-size: #{$value} !important;
  }
}
@each $class, $value in $gap {
  .gap-#{$class} {
    gap: #{$value} !important;
  }
}
@each $class, $value in $gap {
  .innergap-#{$class} {
    > * {
      margin-right: #{$value} !important;
    }
  }
}
h1,
.h1 {
  font-size: var(--font21);
  line-height: 1.33;
  margin-top: 0;
  @extend .fw-bold;
}
h2,
.h2 {
  margin-top: 0;
  font-size: var(--font22);
  line-height: 1.33;
  @extend .fw-medium;
}
h3,
.h3 {
  margin-top: 0;
  font-size: var(--font20);
  line-height: 1.33;
  @extend .fw-bold;
}
h4,
.h4 {
  margin-top: 0;
  font-size: var(--font18);
  line-height: 1.33;
  @extend .fw-medium;
}
h5,
.h5 {
  margin-top: 0;
  font-size: var(--font16);
  line-height: 1.33;
  @extend .fw-medium;
}

@each $class, $value in $fontweight {
  .fw-#{$class} {
    font-weight: #{$value} !important;
  }
}
.inner-childbottomspace {
  margin-bottom: -8px;
  > * {
    margin-bottom: 8px;
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 1 !important;
  color: var(--mdc-outlined-text-field-input-text-placeholder-color) !important;
  .mat-form-field-appearance-fill & {
    color: var(--mdc-filled-text-field-input-text-placeholder-color) !important;
  }
}
::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1 !important;
  color: var(--mdc-outlined-text-field-input-text-placeholder-color) !important;
  .mat-form-field-appearance-fill & {
    color: var(--mdc-filled-text-field-input-text-placeholder-color) !important;
  }
}
:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 1 !important;
  color: var(--mdc-outlined-text-field-input-text-placeholder-color) !important;
  .mat-form-field-appearance-fill & {
    color: var(--mdc-filled-text-field-input-text-placeholder-color) !important;
  }
}
:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1 !important;
  color: var(--mdc-outlined-text-field-input-text-placeholder-color) !important;
  .mat-form-field-appearance-fill & {
    color: var(--mdc-filled-text-field-input-text-placeholder-color) !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.mat-mdc-radio-group {
  // display: flex;
  // column-gap: 40px;
  // row-gap: 10px;
  margin-bottom: -10px;
  // flex-wrap: wrap;
  mat-radio-button {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 40px;
      margin-bottom: 10px;
      @media screen and (max-width: 991.98px) {
        margin-right: 20px;
      }
    }
  }

  .mdc-form-field > label {
    cursor: pointer;
  }
  .mat-mdc-radio-checked label {
    color: var(--primary) !important;
    @extend .fw-semibold;
  }
}
.w-full {
  width: 100%;
}
@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    background-color: var(
      --mat-datepicker-calendar-date-hover-state-background-color
    );
  }
}
.mat-mdc-form-field {
  .mat-mdc-form-field-flex {
    display: block;
  }
  .mdc-notched-outline {
    position: static;
    width: 100%;
    .mdc-notched-outline__notch {
      width: 100% !important;
      border: 0 !important;
      max-width: 100% !important;
      mat-label {
        margin-left: 1px !important;
      }
    }
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      display: none;
    }
  }
  .mat-mdc-form-field-infix {
    padding: 0 !important;
    width: 100%;
    display: flex;
    min-height: auto;
  }
  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
  }
  &.max-h-70 .mat-mdc-text-field-wrapper {
    max-height: 70px;
  }
  &.date-field .mat-mdc-text-field-wrapper {
    display: flex;
    align-items: center;
    padding-left: 20px !important;
    height: 50px;
  }
}
.mdc-floating-label {
  transform: none !important;
  position: static !important;
  color: var(--textGrey2) !important;
  margin-bottom: 10px !important;
  display: block !important;
  @media screen and (max-width: 767.98px) {
    margin-bottom: 6px !important;
  }
}
.mdc-notched-outline [class*="mdc-notched-outline-"] {
  display: none !important;
}
.mat-mdc-form-field {
  width: 100%;
}
// input-lg
.mat-form-field-invalid {
  --inputBorder: #f44336;
  --textGrey2: #f44336;
}
.mat-form-field-appearance-outline:not(.mat-mdc-form-field-type-mat-chip-grid) {
  &:not(.input-lg) {
    input.mdc-text-field__input {
      height: 42px !important;
    }
  }
  .mat-mdc-form-field-infix {
    .mdc-text-field__input {
      border: 1px solid var(--inputBorder) !important;
      font-size: var(--font14);
      padding: 12px;
      border-radius: 8px;
      font-family: "DM Sans", sans-serif;
      width: 100%;

      &[disabled] {
        background: #292829;
        color: #989898;
        border-color: transparent !important;
        body.light & {
          background: #e5e5e5;
        }
      }
    }
    // .mat-mdc-form-field:not(.mat-form-field-invalid) {
    //   .mdc-text-field__input:focus {

    //   }
    // }
    textarea {
      height: 130px;
      resize: none;
    }
  }
}
.mat-form-field-appearance-outline:not(.mat-form-field-invalid) {
  .mdc-text-field__input {
    &:focus {
      --inputBorder: var(--primary);
    }
  }
}
.mdc-text-field--filled.mdc-text-field--disabled {
  body:not(.light) & {
    --mdc-filled-text-field-disabled-container-color: #262626;
    background-color: var(--mdc-filled-text-field-disabled-container-color);
    .mat-mdc-select-value-text {
      color: #a3aab854;
    }
  }
}
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}
.mdc-button.mat-mdc-button-base {
  min-width: auto;
  transition: color 200ms ease;
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: var(
      --mdc-filled-button-label-text-color
    );
  }
  @media screen and (min-width: 992px) {
    &:hover {
      // --mdc-text-button-label-text-color: var(--primaryHover);
    }
  }
}
.mat-mdc-mini-fab {
  .mdc-button__label {
    display: flex;
    justify-content: center;
  }
}
.mdc-button__ripple {
  opacity: 0;
}
.mat-mdc-button {
  padding: 0 !important;
  letter-spacing: 0 !important;
  height: auto !important;
}
.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  height: auto !important;
  // padding: 9px 12px !important;
  padding: 12px 12px !important;
  letter-spacing: 0 !important;
  font-size: var(--font16);
  @media screen and (max-width: 767.98px) {
    padding: 10px 10px !important;
  }
  .mat-mdc-focus-indicator {
    transition: all 200ms ease;
    border-radius: inherit;
  }
  &[disabled] {
    body:not(.light) & {
      --mdc-outlined-button-label-text-color: #5f6164 !important;
      --mdc-outlined-button-outline-color: #302e2e;
      --mdc-filled-button-disabled-container-color: #302e2e;
      --mdc-filled-button-disabled-label-text-color: #5f6164;
    }
  }
  &:hover {
    .mat-mdc-focus-indicator {
      background: rgba($color: #000000, $alpha: 0.07);
      body.light & {
        background: rgba($color: #03a7f6, $alpha: 0.07);
      }
    }
  }
}
.mat-mdc-button[disabled],
.mat-mdc-button.mat-mdc-button-disabled {
  opacity: 0.8 !important;
  color: #898a8b !important;
}
.btn-lg {
  padding: 14px 19px !important;
}
.mdc-checkbox__background {
  width: 20px;
  height: 20px;
}
.mdc-checkbox__ripple {
  display: none;
}
.mdc-form-field > label {
  padding-left: 8px !important;
}
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  border-radius: 12px;
}
.mat-mdc-option {
  min-height: 38px;
}
.mat-mdc-select {
  line-height: var(--mat-select-trigger-text-line-height);
  font-size: var(--mat-select-trigger-text-size);
  letter-spacing: var(--mat-select-trigger-text-tracking);
  .mdc-text-field--outlined & {
    border: 1px solid var(--inputBorder);
    border-radius: 8px;
    padding: 0 12px;
  }
  padding: 0 20px;
  @media screen and (max-width: 575.98px) {
    padding: 0 10px;
  }
  .mat-mdc-select-arrow {
    background: url("./assets/images/icons/ic-arrowdown.svg") no-repeat center;
    width: 18px;
    height: 18px;
    [aria-expanded="true"] & {
      transform: rotate(-180deg);
    }
    svg {
      display: none;
    }
  }
}
.ic-input {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;
}
.relative {
  position: relative;
}
.text-link {
  display: inline !important;
  vertical-align: bottom !important;
  font-size: 100% !important;
  color: var(--primary);
  text-decoration: none;
  font-weight: 700;
  .mdc-button__label {
    font-size: 100% !important;
  }
}
.btn-outline-primary {
  --mdc-filled-button-label-text-color: var(--primary) !important;
  --mdc-filled-button-container-color: transparent !important;
  border: 1px solid var(--primary) !important;
  font-weight: 700;
  flex-shrink: 0;
  transition: color 0.2s ease;
  &:hover {
    --mdc-filled-button-label-text-color: #e5e5e5 !important;
    body.light & {
      --mdc-filled-button-label-text-color: var(--primary) !important;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.input-rightspace,
.password-field {
  input {
    padding-right: 40px !important;
  }
}
.password-field {
  position: relative;
  &.active {
    .eye-icon {
      background-position: 0 0;
    }
  }
  .eye-icon {
    @extend .ic-input;
    cursor: pointer;
    width: 18px;
    height: 18px;
    background: url("./assets/images/icons/view-unview.svg") no-repeat -28px 0;
  }
}


.icon-input {
  input {
    padding-right: 40px !important;
  }
}
.icon-input {
  position: relative;
  &.active {
    .eye-icon {
      background-position: 0 0;
    }
  }
  span {
    @extend .ic-input;
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
}


.link-btn {
  padding-bottom: 0px !important;
  border-bottom: 1px solid currentColor !important;
  border-radius: 0 !important;
}
.block {
  > *:last-child {
    margin-bottom: 0 !important;
  }
}
.btn-dark {
  --mdc-filled-button-container-color: var(--authInnerBox);
  --mdc-filled-button-label-text-color: var(--white);
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center !important;
}
.text-end {
  text-align: end;
}
.mat-mdc-button {
  .mat-mdc-button-touch-target {
    height: 100%;
  }
}
.input-error {
  font-size: 12px;
  line-height: 1.8;
}
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}
.mat-mdc-dialog-content {
  max-height: calc(var(--app-height) - 120px) !important;
  overflow: auto !important;
}
.page-wrapper {
  padding: 0 25px 20px;
  width: calc(100% - 152px);
  @media screen and (max-width: 991.98px) {
    padding: 0 15px 20px;
  }
  @media screen and (max-width: 767.98px) {
    padding: 0 10px 10px;
  }
}
.mdc-linear-progress__bar-inner {
  border-radius: 64px;
}
.main-wrapper {
  padding-top: 95px;
  width: 100%;
  transition: 0.5s ease;
  @media screen and (max-width: 575.98px) {
    padding-top: 75px;
  }
}
.text-decoration-none {
  text-decoration: none;
}
.text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.backbtn-wrap {
  @media screen and (max-width: 767.98px) {
    position: relative;
    h1 {
      padding-left: 38px !important;
    }
  }
}
.loader-btn {
  .mdc-circular-progress__circle-clipper {
    width: 24px;
  }
}
.backbtn {
  padding: 13px 20px !important;
  @media screen and (max-width: 767.98px) {
    &:not(.show) {
      &.mdc-button {
        font-size: 0;
        padding: 0 !important;
        width: 30px;
        height: 30px !important;
        border-radius: 4px;
        position: absolute;
        left: 10px;
        border: 1px solid var(--grey-dark) !important;
        background: #f6f8fa;
        top: 14px;
        .mdc-button__label {
          font-size: 0;
          gap: 0 !important;
        }
      }
    }
  }
  .mdc-button__label {
    display: grid;
    grid-template-columns: 8px 1fr;
    align-items: center;
    gap: 10px;
    font-weight: 700;
  }
}
.custom-scroll,
.mat-mdc-select-panel,
.mat-mdc-dialog-content,
.mat-mdc-menu-panel,
textarea,
.auth-innerbox {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--scrollBg);
    border-radius: 10px;
    &:hover {
      background: var(--scrollBg);
    }
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--scrollThumb);
    border: 1px solid var(--scrollBorder);
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollThumb);
  }
}

.img-fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.item-infobox {
  .item-image {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    display: block;
    border-radius: 4px;
    overflow: hidden;
  }
}
.form-label {
  font-family: var(--mdc-outlined-text-field-label-text-font);
  font-size: var(--mdc-outlined-text-field-label-text-size);
  font-weight: var(--mdc-outlined-text-field-label-text-weight);
  letter-spacing: var(--mdc-outlined-text-field-label-text-tracking);
  color: var(--textGrey2) !important;
  display: block;
  margin-bottom: 10px;
  @media screen and (max-width: 767.98px) {
    margin-bottom: 6px;
  }
}
.form-control {
  border: 1px solid var(--inputBorder);
  border-radius: 8px;
  height: 42px;
  width: 100%;
  padding: 12px;
  background: transparent;
  outline: 0 !important;
  div:not(.mat-form-field-invalid) & {
    &:focus {
      --inputBorder: var(--primary);
      outline: 0 !important;
    }
  }
}
.mat-form-field-invalid {
  .file-text {
    color: var(--mdc-outlined-text-field-error-label-text-color) !important;
  }
}
.pointer-none {
  pointer-events: none;
}
.file-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  padding-right: 40px;
}
.mat-datepicker-toggle {
  .mdc-icon-button {
    background: url("./assets/images/icons/ic-date.svg") no-repeat center;
    --mdc-icon-button-state-layer-size: 24px !important;
    padding: 0 !important;
    svg {
      display: none;
    }
  }
}
.mobile-full {
  @media screen and (max-width: 575.98px) {
    width: 100%;
  }
}
.hover-link {
  transition: color 200ms ease;
  &:hover {
    color: var(--primary) !important;
  }
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.search-inputbx {
  input {
    body.light & {
      --inputBorder: #989898;
    }
  }
}
body.light {
  .table-datebox {
    --grey-dark: #cbced1;
  }
}
.input-error {
  border: red 1px solid;
}
.text-gredient {
  background: linear-gradient(45.39deg, #00a5fc -6.5%, #00ffff 103.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

// increment Decrement Counter
.counterupdate-btn {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  transition: all 200ms ease;
}
.product-updatewrap {
  > *:not(:last-child) {
    margin-right: 5px;
  }
}
.decrese-btn {
  border: 1px solid var(--textGrey2);
  &:hover {
    background: var(--textGrey2);
  }
  body.light & {
    background: var(--textGrey2);
  }
}
.increase-btn {
  background: linear-gradient(45.39deg, #00a5fc -6.5%, #00ffff 103.76%);
}
.counter-updater {
  width: 28px;
  height: 24px;
  background: transparent;
  border: 0;
  font-weight: 500;
  color: var(--white);
  padding: 0;
  text-align: center;
  outline: 0 !important;
  font-size: var(--font16);
}

/* Starts Toaster Css */
.toast-container {
  top: auto !important;
  right: 12px !important;
  bottom: 12px !important;
}

.ngx-toastr {
  &.toast-success {
    border: 2px solid #ebffe0;
    background-color: #fff;
    width: 370px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    padding-left: 100px !important;
    position: relative !important;
    right: 10px;
    min-height: 74px;
    line-height: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    :before {
      background-color: #ebffe0;
      content: "";
      position: absolute;
      left: 0;
      width: 80px;
      height: 100%;
      z-index: -0;
      top: 0;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 76px;
      height: 100%;
      z-index: 0;
      top: 0;
      background-image: url(./assets/images/success_icon.png);
      background-position: 16px;
      background-repeat: no-repeat;
    }
    .toast-title {
      color: #8e8e8e;
      font-size: 15px;
      width: 100%;
      float: left;
    }
    .toast-message {
      color: #8e8e8e;
      font-size: 14px;
      width: 100%;
      float: left;
    }
  }
  &.toast-error {
    border: 2px solid #ffc9c9;
    background-color: #fff;
    width: 370px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    padding-left: 100px !important;
    position: relative !important;
    right: 10px;
    min-height: 74px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &:before {
      background-color: #ffc9c9;
      content: "";
      position: absolute;
      left: 0;
      width: 80px;
      height: 100%;
      z-index: -0;
      top: 0;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 46px;
      height: 100%;
      z-index: -0;
      top: 0;
      background-image: url(./assets/images/error_icon.png);
      background-size: 6px;
      background-position: 36px;
      background-repeat: no-repeat;
    }
    .toast-title {
      color: #8e8e8e;
      font-size: 15px;
      width: 100%;
      float: left;
    }
    .toast-message {
      color: #8e8e8e;
      font-size: 14px;
      width: 100%;
      float: left;
    }
  }
}
/* Ends Toaster Css */

/* Starts No Records Found */
.no-records {
  background: var(--textGreyLight);
  height: calc(var(--app-height) - 220px);
  width: 100%;
  border-radius: 12px;
  @media screen and (max-width: 767.98px) {
    img {
      max-width: 200px;
    }
  }
}
/* Ends No Records Found */
.image-invalid {
  border-color: red !important;
}
.table-loader {
  top: 95px;
  position: fixed !important;
  left: 152px;
  right: 0;
  @media screen and (max-width: 1199.98px) {
    left: 122px;
  }
  @media screen and (max-width: 991.98px) {
    left: 0;
    top: 75px;
  }
  @media screen and (max-width: 575.98px) {
    top: 65px;
  }
}
.spinner-background {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 12px;
  font-weight: 700;
  color: #f1c21b;
}

@media screen and (min-width: 1024px) {
  .open_checkout {
    .main-wrapper {
      width: calc(100% - 568px);
    }
    // .header-wrapper {
    //   width: calc(100% - 568px) !important;
    // }
    .menu-listwrap {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1340px) {
  .open_checkout {
    .menu-listwrap {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}

.imput-text-small {
  width: calc(100% - 40px);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--inputBorder);
  border-radius: 8px;
  height: 42px;
  width: 100%;
  padding: 12px 35px 12px 12px;
  background: transparent;
  outline: 0 !important;
}
.divider-line {
  margin: 0 16px;
}
.dark {
  .timepicker__body, .timepicker__actions {
    background-color: #000 !important;
  }
  .clock-face {
    background-color: #252525 !important;
  }
}