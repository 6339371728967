
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?rcad3u');
  src:  url('../fonts/icomoon.eot?rcad3u#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?rcad3u') format('truetype'),
    url('../fonts/icomoon.woff?rcad3u') format('woff'),
    url('../fonts/icomoon.svg?rcad3u#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-ic-receipt:before {
  content: "\e920";
}
.icon-ic-transaction:before {
  content: "\e921";
}
.icon-ic-cashback:before {
  content: "\e922";
}
.icon-ic-product:before {
  content: "\e923";
}
.icon-ic-settings:before {
  content: "\e924";
}
.icon-ic-checkout:before {
  content: "\e925";
}

.icon-ic-download:before {
  content: "\e91f";
  color: #03a7f6;
}
.icon-ic-menu:before {
  content: "\e918";
}
.icon-ic-inventory:before {
  content: "\e919";
}
.icon-ic-vendor:before {
  content: "\e91a";
}
.icon-ic-purchase:before {
  content: "\e91b";
}
.icon-ic-online-order:before {
  content: "\e91c";
}
.icon-ic-setting:before {
  content: "\e91d";
}
.icon-ic-order-history:before {
  content: "\e91e";
}
.icon-ic-location:before {
  content: "\e917";
  color: #e5e5e5;
}
.icon-edit-pencil:before {
  content: "\e916";
  color: #03a7f6;
}
.icon-ic-trash:before {
  content: "\e915";
  color: #e53935;
}
.icon-logout .path1:before {
  content: "\e913";
  opacity: 0.992;
}
.icon-logout .path2:before {
  content: "\e914";
  margin-left: -1em;
  opacity: 0.979;
}
.icon-image-plus:before {
  content: "\e912";
  color: #989898;
}
.icon-ic-filter:before {
  content: "\e910";
  color: #989898;
}
.icon-ic-price:before {
  content: "\e911";
  color: #989898;
}
.icon-eye:before {
  content: "\e902";
  color: #03a7f6;
}
.icon-development:before {
  content: "\e90f";
  color: #03a7f6;
}
.icon-ic-target:before {
  content: "\e901";
  color: #989898;
}
.icon-dark-mode:before {
  content: "\e903";
}
.icon-maps:before {
  content: "\e904";
  color: #e5e5e5;
}
.icon-sun:before {
  content: "\e905";
  color: #989898;
}
.icon-station:before {
  content: "\e906";
}
.icon-inventory:before {
  content: "\e907";
}
.icon-billing:before {
  content: "\e908";
}
.icon-reservation:before {
  content: "\e909";
}
.icon-time-management:before {
  content: "\e90a";
}
.icon-cart:before {
  content: "\e90b";
}
.icon-order-status:before {
  content: "\e90c";
}
.icon-pos-terminal:before {
  content: "\e90d";
}
.icon-dinning-table:before {
  content: "\e90e";
}
.icon-home:before {
  content: "\e900";
}
.icon-message-text:before {
  content: "\e926";
  color: #00b42c;
}
.icon-icon-clock:before {
  content: "\e927";
  color: #989898;
}